.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.MuiButton-root,
a.MuiButton-root {
  border-radius: 25px;
}

button.MuiButton-containedError {
  background: #FFD8D6;
  color: #921E16;
}

button.MuiButton-containedError:hover {
  background:  #F88078;
}

/* Filtre */

.filter {
  display: flex;
  align-items: center;
  padding: 2em 0;
}

.filter .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  border-radius: 50px;
}

.filter .MuiIconButton-root {
  background-color: #2fa37c;
  color: #fff;
}

/*Card */
.MuiCard-root .MuiIconButton-root {
  background-color: rgba(47, 163, 124, 0.08);
  color: #2fa37c;
}

/* datePicker
.MuiTextField-root {
  display: flex !important;
} */
.datePicker .MuiTextField-root {
  display: flex !important;
}


.leaflet-container{
  width: 100%;
  height: 100%;
}