.inf-btn-ctn {
  display: flex !important;
  align-items: center;
  padding: 4px 14px;
  gap: 8px;
  border-radius: 16px;
  height: flex;
}

.warning {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f44336;
}

.valid {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #4caf50;
}
.info {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #ff9800;
}
